import { SPORTS_URLS, PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import { PATH_IMAGES } from '~/constants/path'
const PATH_IMAGE = '/assets/images/components/desktop/footer/v2/'
export const FOOTER_NAVIGATION = [
  {
    title: 'Thể thao',
    subCategories: [
      { title: 'Lịch thi đấu', link: SPORTS_URLS.SCHEDULES },
      { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
    ]
  },
  {
    title: 'Trò chơi',
    subCategories: [
      { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
      { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
      { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
      { title: 'Games bài', link: LOBBY_GAME_URLS.GAME_BAI },
      { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
      { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
      { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
      { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
    ]
  },
  {
    title: 'Về chúng tôi',
    subCategories: [
      { title: `Giới thiệu ${process.env.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
      { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY}` },
      { title: 'Bảo mật', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SECURITY}` },
      { title: 'Chơi games có trách nhiệm', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RESPONSIBILITY}` },
      { title: 'Quy định chung', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RULE}` }
    ]
  },
  {
    title: 'Hỗ trợ 24/7',
    subCategories: [
      {
        title: 'Trực tuyến 24/7',
        type: 'BLANK',
        link: `https://secure.livechatinc.com/licence/${process.env.LIVE_CHAT}/v2/open_chat.cgi`
      },
      { title: 'Cộng đồng DA88', type: 'BLANK', link: process.env.TELEGRAM_CSKH },
      { title: 'Hướng dẫn Nạp/Rút', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.DEPOSIT}` }
    ]
  },
  {
    title: 'Thông tin',
    subCategories: [{ title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }]
  }
]
export const getFooterNavigation = () => {
  const { $config } = useNuxtApp()
  return {
    NAVIGATIONS: [
      {
        title: 'Trò chơi',
        subCategories: [
          { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
          { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
          { title: 'Games bài', link: LOBBY_GAME_URLS.GAME_BAI },
          { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
          { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
          { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
          { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
          { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
        ]
      },
      {
        title: 'Về chúng tôi',
        subCategories: [
          { title: `Giới thiệu ${$config.public.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
          { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY}` },
          { title: 'Bảo mật', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SECURITY}` },
          { title: 'Quy định chung', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RULE}` }
        ]
      },
      {
        title: 'Thông tin',
        subCategories: [{ title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }]
      }
    ]
  }
}

export const DEPOSIT_LIST = [
  {
    title: 'CodePay',
    image: `${PATH_IMAGE}icon-codepay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA
  },
  {
    title: 'P2P',
    image: `${PATH_IMAGE}icon-p2p.svg`,
    link: ACCOUNT_URLS.P2P
  },
  {
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}icon-crypto.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO
  },
  {
    title: 'Momo',
    image: `${PATH_IMAGE}icon-momo.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO
  },
  {
    title: 'Viettel Money',
    image: `${PATH_IMAGE}icon-viettelpay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY
  },
  {
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}icon-card.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CARD
  }
]

export const getFooterContent = () => {
  const { $config } = useNuxtApp()

  return {
    SLOGAN: `${$config.public.BRANCH_NAME} - Nhà cái cá cược uy tín nhất Châu Á, nạp rút siêu tốc.`,
    DESCRIPTION: `Nhà cái ${$config.public.BRANCH_NAME} là trang cược bóng đá nhanh nhất trên thị trường hiện nay, hoàn trả mỗi ngày cao nhất 1.8%. ${$config.public.BRANCH_NAME} cung cấp hai loại bảng kèo K-Sports; C-Sports và tỷ lệ cược liên tục cập nhật từng giây. Lịch thi đấu mỗi ngày, phân tích trận đấu, soi kèo banh Ngoại Hạng Anh, Cúp C1, Serie A, Laliga, Bundesliga, Ligue 1,... và tất cả các giải lớn nhỏ khác. Trải nghiệm mượt mà, mọi lúc mọi nơi.`,
    SLOGAN_DESCRIPTION: `${$config.public.BRANCH_NAME} - Nhà cái đẳng cấp Châu Âu. ${$config.public.BRANCH_NAME} đem đến cho khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao Ảo, Casino, Number Games, Keno, Sòng Bài Số...`,
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.public.BRANCH_NAME} All Rights Reserved.`
  }
}

export const getFooterContact = () => {
  const { $config } = useNuxtApp()

  return {
    SUPPORT_MENU: [
      {
        name: 'Live chat 24/7',
        url: '',
        activeUrl: [],
        isLiveChat: true,
        icon: PATH_IMAGES + 'icon-livechat.webp',
        iconMB: PATH_IMAGES + 'icon-livechat-mb.svg'
      },
      {
        name: `Telegram ${$config.public.BRANCH_NAME}`,
        url: $config.public.TELEGRAM_CSKH,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-telegram.webp',
        iconMB: PATH_IMAGES + 'icon-telegram-mb.svg'
      },
      {
        name: 'Cộng đồng',
        url: $config.public.TELEGRAM_GROUP,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-community.webp',
        iconMB: PATH_IMAGES + 'icon-community-mb.svg'
      },
      {
        name: 'Hotline',
        url: `tel:${$config.public.CONTACT_PHONE}`,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-phone.webp',
        iconMB: PATH_IMAGES + 'icon-phone-mb.svg'
      },
      {
        name: 'Fanpage',
        url: $config.public.FACEBOOK_GROUP,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-fb.webp',
        iconMB: PATH_IMAGES + 'icon-fb-mb.svg',
        className: 'icon-fanpage'
      }
    ]
  }
}
export const getFooterAboutUs = () => {
  const { $config } = useNuxtApp()

  return {
    INTRODUCE_LIST: [
      {
        name: 'Giới thiệu',
        icon: PATH_IMAGES + 'icon-introduce.svg',
        url: '/gioi-thieu'
      },
      {
        name: 'Quy định',
        icon: PATH_IMAGES + 'icon-policy.svg',
        url: '/gioi-thieu/quy-dinh-chung'
      },
      {
        name: 'Bảo mật',
        icon: PATH_IMAGES + 'icon-privacy.svg',
        url: '/gioi-thieu/chinh-sach-bao-mat'
      },
      {
        name: 'Hướng dẫn',
        icon: PATH_IMAGES + 'icon-desc.svg',
        url: '/huong-dan'
      },
      {
        name: 'Tin tức',
        icon: PATH_IMAGES + 'icon_news.svg',
        url: '/tin-tuc',
        type: 'news'
      }
    ]
  }
}
